import React from 'react'
import styles from './styles'
import formatters from 'lib/formatters'
import { IconSearch } from '../../../../components/Icons/IconSearch'
import { S3FilePreview } from '../../../../components/S3FilePreview'

const DepositDetailsSection = ({
  amount,
  feeRate,
  hasAmount,
  depositDate,
  depositType,
  fee,
  enrollmentFee,
  hasEnrollmentFee,
  isRefund,
  isTransfer,
  imageKey,
}) => {
  const headerColumns = [
    { id: 'deposit', label: 'DEPOSIT', className: styles.deposit },
    { id: 'date', label: 'Date', className: styles.date },
    { id: 'type', label: 'Type', className: styles.type },
    { id: 'amount', label: 'Amount', className: styles.amount, show: hasAmount },
    { id: 'appliedFee', label: 'APPLIED FEE', className: styles.appliedFee },
    { id: 'rate', label: 'Rate', className: styles.rate },
    { id: 'adminFee', label: 'Admin Fee', className: styles.adminFee },
    { id: 'enrollFee', label: 'Enroll', className: styles.enrollFee, show: hasEnrollmentFee },
  ]

  const rowData = [
    { id: 'deposit', content: <S3FilePreview icon file_key={imageKey} />, className: styles.depositIcon },
    { id: 'date', content: formatters.date(depositDate), className: styles.date },
    { id: 'type', content: depositType ? formatters.legibleDepositType(depositType) : '', className: styles.type },
    { id: 'amount', content: formatters.money(amount), className: styles.amount, show: hasAmount },
    { id: 'appliedFee', content: null, className: styles.appliedFee },
    { id: 'rate', content: `${(feeRate * 100).toFixed(2) || 0}%`, className: styles.rate },
    { id: 'adminFee', content: `$${(((amount - enrollmentFee) * feeRate) / 100.0).toFixed(2)}`, className: styles.adminFee },
    { id: 'enrollFee', content: formatters.money(enrollmentFee || 0), className: styles.enrollFee, show: hasEnrollmentFee },
  ]

  return (
    <div className={styles.cardMarginBot}>
      <h5 className={styles.sectionTitle}>Deposit Review</h5>
      <div className={styles.sectionNoMargin}>
        <div className={styles.cardMarginBotWrapperBottom}>
          <table className={styles.depositTable}>
            <thead className={styles.depositTableHead}>
              <tr className={styles.depositTableRow}>
                {headerColumns
                  .filter((column) => column.show !== false)
                  .map((column) => (
                    <th key={column.id} className={column.className}>
                      {column.label}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className={styles.depositTableBody}>
              <tr>
                {rowData
                  .filter((column) => column.show !== false)
                  .map((column) => (
                    <td key={column.id} className={column.className}>
                      {column.content}
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
          {isRefund && (
            <div>
              <p className={styles.refund}>This deposit is a REFUND</p>
            </div>
          )}
          {isTransfer && (
            <div>
              <p className={styles.refund}>This deposit is a TRANSFER</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DepositDetailsSection
