// @flow
import formatters from 'lib/formatters'
import { httpGet } from 'lib/http'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './header.css'
import { IconRedirect } from '../Icons/IconRedirect'
import { renderCheckingBalance } from '../../routes/Protected/Distributions/components/Header/Header'

const renderClosingHeader = (status) => {
  let component = <div></div>

  if (status == 'closed') {
    component = <h5 className={styles.closedStatus}>{status.toUpperCase()}</h5>
  } else if (status == 'closing') {
    component = <h5 className={styles.closingStatus}>{status.toUpperCase()}</h5>
  } else if (status == 'suspended') {
    component = <h5 className={styles.closingStatus}>{status.toUpperCase()}</h5>
  }

  return component
}

const renderDeceasedHeader = (dod) => {
  if (dod) {
    return <h5 className={styles.deceasedFlag}>DECEASED</h5>
  } else {
    return <div></div>
  }
}

const GPTAccountRefV2 = (props) => {
  let { number, beneficiary, touchNav, children } = props

  if (!beneficiary) {
    beneficiary = { name: '' }
  }

  const [dod, setDod] = useState('')
  const [lastUpdate, setLastUpdate] = useState('')
  const [openDate, setOpenDate] = useState('')
  const [status, setStatus] = useState('')
  const [trustName, setTrustName] = useState('')
  const [productDescription, setProductDescription] = useState('')
  const [checkingBalance, setCheckingBalance] = useState('')

  useEffect(() => {
    httpGet(`/admin/accounts/${number}?summary=true`)
      .then((data) => {
        setDod(data.dod)
        setLastUpdate(data.lastUpdate)
        setOpenDate(data.openDate)
        setStatus(data.status)
        setTrustName(data.trustName)
        setProductDescription(data.productDescription)
        setCheckingBalance(data.balance)
      })
      .catch((err) => null)
  }, [number])

  return (
    <section className={styles.accountRef}>
      <section className={styles.cardMarginBot}>
        <section className={styles.sectionNoMargin}>
          <div className={`${styles.titleBox} ${touchNav ? styles.titleHeaderHeight : ''}`}>
            <div className={styles.titleBoxRow}>
              <div className={`${styles.nameWrapper}`}>
                <span className={styles.titleName}>
                  <div className={styles.trusts}>
                    <div className={styles.trustsHeader}>
                      <Link to={`/accounts/${number}`} className={styles.redirectIcon} target="_blank" referrerPolicy="no-referrer">
                        <h5 className={styles.accountNumber}>{number}</h5>
                      </Link>
                      <Link to={`/accounts/${number}`} className={styles.redirectIcon} target="_blank" referrerPolicy="no-referrer">
                        <IconRedirect h={16} w={16} />
                      </Link>
                    </div>
                    {renderClosingHeader(status)}
                  </div>
                </span>
                <div>
                  <div className={styles.accountNumberLine}>
                    <h1>
                      <Link to={`/accounts/${number}`} className={styles.beneName} target="_blank" referrerPolicy="no-referrer">
                        {beneficiary.name}
                      </Link>
                    </h1>
                    {dod && renderDeceasedHeader(dod)}
                  </div>
                  <div className={styles.trustName}>{trustName}</div>
                  <div className={styles.productDescription}>{productDescription}</div>
                </div>
              </div>
              <div className={`${styles.balanceWrapper}`}>
                <div>
                  <p className={styles.boxLabel}>Main Account Balance</p>
                  <h2>{renderCheckingBalance(checkingBalance)}</h2>
                </div>
              </div>

              <div className={`${styles.accountStatusWrapper}`}>
                <div>
                  <p className={styles.accountBoxLabel}>Account Information</p>
                  <p className={styles.accountStatusCaption}>Last updated {formatters.date(lastUpdate)}</p>
                </div>
                <div>
                  <p className={`${styles.accountBoxLabel} ${styles.accountBoxStatus}`}>{formatters.legibleAccountStatus(status)}</p>
                  <p className={styles.accountStatusCaption}>Open {formatters.date(openDate)}</p>
                </div>
              </div>
            </div>
            <div className={styles.childrenItems}>{children ?? null}</div>
          </div>
        </section>
      </section>
    </section>
  )
}

export default GPTAccountRefV2
