// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import type { NotificationProps } from './NotificationContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'

import formatters from 'lib/formatters'

import styles from './styles.css'

type Props = {
  canResolve: boolean,
  notifications: [NotificationProps],
  resolveNotification: Function,
}

const Notification = (props: Props) => {
  const { canResolve, notifications, resolveNotification } = props
  const renderNotification = (notification: NotificationProps) => {
    const { id, status, type, message, inserted_at, tagged_gpt_accounts, tagged_emails, ticket_id } = notification

    const typeStyle = status === 'alarm' ? styles.warn : styles.info
    const renderEmails = () => tagged_emails.reduce((acc, { tag, email }) => (tag === 'caller' ? [email, ...acc] : acc), []).join(', ')

    const renderAccount = (tag, { number }, index) => (
      <span key={`${number}-${tag}`}>
        {index > 0 && ', '}
        <Link to={`/accounts/${number}`}>
          {number}
          {tag != 'general' && ': '}
          {formatters.legibleNotificationTag(tag)}
        </Link>
      </span>
    )

    const renderAccounts = () =>
      tagged_gpt_accounts.reduce((acc, { tag, gpt_account }, index) => [renderAccount(tag, gpt_account, index), ...acc], []).reverse()

    const renderTicket = () => ticket_id && <Link to={`/tickets/${ticket_id}`}>View Ticket</Link>

    const renderTools = () =>
      status == 'alarm' && (
        <div className={styles.notificationTools}>
          <FontAwesomeIcon size="lg" className={styles.typeIcon} icon={['fas', 'exclamation-circle']} />
          {canResolve && (
            <FontAwesomeIcon size="lg" className={styles.resolveButton} icon="check-circle" onClick={() => resolveNotification(notification)} />
          )}
        </div>
      )

    const emails = renderEmails()
    const accounts = renderAccounts()
    const ticket = renderTicket()

    return (
      <MenuItem key={id} className={`${styles.notification} ${typeStyle}`}>
        <div className={styles.notificationContent}>
          {(type || inserted_at) && (
            <div>
              {type && <strong className={styles.type}>{formatters.legibleNotificationType(type)}</strong>}{' '}
              {inserted_at && <small>{formatters.eventTimestamp(inserted_at)}</small>}
            </div>
          )}

          {emails && <div id="renderEmails">{emails}</div>}

          {message && <div id="renderMessage">{message}</div>}

          {accounts && <div id="renderAccounts">{accounts}</div>}

          {ticket && <div id="renderTicket">{ticket}</div>}
        </div>
        {renderTools()}
      </MenuItem>
    )
  }

  const renderNotifications = () => {
    const hasActiveWarn = () => notifications.find(({ status }) => status == 'alarm') != undefined

    let bellStyle = styles.info
    const renderBell = () => {
      if (hasActiveWarn(notifications)) {
        bellStyle = styles.warn
      }

      return <FontAwesomeIcon className={`${styles.bell} ${bellStyle}`} size="lg" icon="bell" />
    }
    const options = ['one', 'two', 'three']
    return (
      <Menu menuClassName={styles.notificationsWrap} menuButton={<MenuButton className={styles.notificationButton}>{renderBell()}</MenuButton>}>
        {notifications.length > 0 ? notifications.map(renderNotification) : <div className={styles.empty}>No Notifications</div>}
      </Menu>
    )
  }

  return renderNotifications()
}

export default Notification
