// @flow
import { Dialog } from 'components/Dialog'
import { S3FilePreview } from 'components/S3FilePreview'
import Ticket from 'components/Ticket'
import React from 'react'
import { Reference } from './ReferenceRef'
import styles from './styles'
import { WarningsAcknowledgementRef } from './WarningsAcknowledgementRef'
import DepositReviewRef from './DepositReviewRef'
import RejectionNotesRef from './RejectionNotesRef'

type Props = {
  canSubmit: boolean,
  disabled: boolean,
  dispatch: Function,
  errors: Object,
  isDeleting: boolean,
  isSubmitting: boolean,
  onApproval: Function,
  onBeginDelete: Function,
  onCancelDelete: Function,
  onChange: Function,
  onDelete?: Function,
  onSubmit: Function,
  ticket: Object,
  values: Object,
}

export const ApprovalStation = (props: Props) => {
  const { canSubmit, disabled, isDeleting, isSubmitting, onApproval, onBeginDelete, onCancelDelete, onDelete, onSubmit, ticket, values } = props
  const { acknowledgeDuplicateCheck, acknowledgeEarlyDate } = values
  const header = { subheader: 'Deposit', title: 'Approval' }
  const reference = ticket && <Reference {...props} />
  const rejectionError = props.errors.rejection_notes
  const errorWrapper = rejectionError && styles.errorWrapper

  return (
    <Ticket header={header} reference={reference}>
      {ticket.rejection_notes && <RejectionNotesRef notes={ticket.rejection_notes} />}
      <DepositReviewRef
        amount={ticket.amount}
        balance={ticket.balance}
        customFee={ticket.custom_fee}
        feeRate={ticket.fee_rate}
        feeSchedule={ticket.fee_schedule}
        enrollmentFee={ticket['enrollment_fee']}
        isRefund={ticket.is_refund}
        isTransfer={ticket.is_transfer}
        depositDate={ticket.deposit_date}
        depositType={ticket.deposit_type}
        imageKey={ticket.verification_image_key}
      />
      {/* <DepositVerificationRef {...props} /> */}
      <form onSubmit={onSubmit}>
        <WarningsAcknowledgementRef
          disabled={disabled}
          warnings={ticket.warnings}
          onChange={props.onChange}
          acknowledgeEarlyDate={acknowledgeEarlyDate}
          acknowledgeDuplicateCheck={acknowledgeDuplicateCheck}
        />
        {(ticket.rejection_notes == null || ticket.rejection_notes == '') && (
          <section className={`${styles.sectionNoMargin} ${styles.rejectionWrapper}`}>
            <div className={`${errorWrapper} ${styles.textareaWrapper}`}>
              <label className={styles.label} htmlFor="rejection_notes">
                Reasons for Rejection {rejectionError && `- ${rejectionError}`}
              </label>
              <textarea className={styles.textarea} id="rejection_notes" name="rejection_notes" onChange={props.onChange} />
            </div>
          </section>
        )}
        <section className={styles.buttons}>
          <div className={styles.buttonsLeft}>
            {onDelete && (
              <button className={styles.buttonDanger} disabled={isSubmitting} onClick={onBeginDelete}>
                Delete
              </button>
            )}
          </div>
          <div className={styles.buttonsRight}>
            {(ticket.rejection_notes == null || ticket.rejection_notes == '') && (
              <button className={styles.buttonDanger} disabled={disabled} id="reject" onClick={(event) => onApproval(event, false)}>
                Reject
              </button>
            )}
            <button className={styles.button} disabled={!canSubmit} id="approve" onClick={(event) => onApproval(event, true)}>
              Make Deposit
            </button>
          </div>
        </section>
      </form>
      {/* <div className={styles.policyInfo}>
        <PolicyInformation />
      </div> */}

      {isDeleting && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="Yes, Delete"
          headerText="Are you sure you want to delete this deposit?"
          onCancel={onCancelDelete}
          onConfirm={onDelete}
        />
      )}
    </Ticket>
  )
}
