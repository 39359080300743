import Input from '@mui/joy/Input'
import React from 'react'
import { NumericFormat } from 'react-number-format'
import styles from './styles.css'

const NumericFormatAdapter = React.forwardRef(function NumericFormatAdapter(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange(
          other.flattenValue
            ? values.value
            : {
                target: {
                  name: props.name,
                  value: values.value,
                },
              },
        )
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  )
})

const CurrencyFormatInput = ({ name, value, setValue, placeholder, className, disabled, onBlur, flattenValue, ...extra }) => {
  const formattedValue = value != null ? String(value) : ''

  return (
    <Input
      required
      disabled={disabled}
      className={`${className} ${styles.currencyInput}`}
      name={name}
      value={formattedValue}
      onChange={setValue}
      placeholder={placeholder}
      slotProps={{
        input: {
          component: NumericFormatAdapter,
          flattenValue: flattenValue ?? null,
        },
      }}
      sx={{
        '--Input-focusedThickness': '1px',
        '--Input-radius': '3px',
        boxShadow: 'none',
        fontFamily: 'inherit',
        color: '#31333F',
        background: 'transparent',
        fontWeight: `${value}` ? 700 : 500,
        '&::before': {
          transition: 'all 0.25s',
        },
        '&:focus-within': {
          borderColor: '#edeced',
          background: '#E3EBF5',
        },
      }}
      onBlur={onBlur ? onBlur : undefined}
      {...(extra || {})}
    />
  )
}

export default CurrencyFormatInput
