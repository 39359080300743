import styles from './styles.css'

function RejectionNotesRef({ notes }) {
  return (
    <div className={styles.rejectionNotes}>
      <h5 className={styles.sectionTitle}>Rejection Notes</h5>
      <div className={styles.sectionNoMargin}>{notes}</div>
    </div>
  )
}

export default RejectionNotesRef
