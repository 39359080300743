// @flow
import React from 'react'
import Dropzone from 'react-dropzone'
import type { File } from 'lib/types'
import { FilePreview } from 'components/FilePreview'
import { S3FilePreview } from 'components/S3FilePreview'
import styles from './styles'

type Props = {
  existing: String,
  name: string,
  onChange: Function,
  value: File,
}

const buildUploadParams = async (file) => {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const data = await toBase64(file)
  const params = {
    content_type: file.type,
    data: data.split(',')[1],
  }

  return params
}

const dropHandler = (onChange, name) => {
  return (acceptedFiles, rejectedFiles) => {
    const file = acceptedFiles[0]

    buildUploadParams(file).then((params) => {
      onChange({ target: { name: name, value: params } })
    })
  }
}

const renderBlobPreview = (value: File) => (
  <div className={styles.previewWrapper}>
    <FilePreview file={value} />
  </div>
)

const renderS3Preview = (key) => {
  return (
    <div className={styles.previewWrapper}>
      <S3FilePreview file_key={key} />
    </div>
  )
}

const renderBlobOrS3Preview = (value) => {
  if (value && value.key) {
    return renderS3Preview(value.key)
  } else if (value && value.content_type) {
    return renderBlobPreview(value)
  } else {
    return <div></div>
  }
}

export const S3FileInput = (props: Props) => {
  const { name, onChange, value, existing } = props

  return (
    <div>
      {!value && existing != null ? renderBlobOrS3Preview(existing) : renderBlobOrS3Preview(value)}
      <Dropzone
        className={styles.dropzoneArea}
        disablePreview={true}
        activeClassName={styles.drag}
        multiple={false}
        name={name}
        onDrop={dropHandler(onChange, name)}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={styles.dropzoneArea} {...getRootProps()}>
            <div className={styles.upIcon} />
            <div className={styles.uploadText}>Drop here or click to browse</div>
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
    </div>
  )
}
