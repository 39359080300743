// @flow
import { S3FilePreview } from 'components/S3FilePreview'
import Ticket from 'components/Ticket'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'
import { Reference } from './ReferenceRef'
import styles from './styles'

type Props = {
  dispatch: Function,
  ticket: Object,
  depositDate: string,
  amount: number,
  values: Object,
}

const DepositVerificationRef = ({ values: { verification_image_key } }: Props) => {
  const title = 'Scanned Check / Deposit Verification'

  return (
    <div className={styles.cardMarginBot}>
      <h5 className={styles.sectionTitle}>{title}</h5>
      <div className={styles.sectionNoMargin}>
        <S3FilePreview file_key={verification_image_key} />
      </div>
    </div>
  )
}

export const ClosedStation = (props: Props) => {
  const { ticket, depositDate } = props
  const { account_number } = ticket
  const header = { subheader: 'Deposit', title: 'Ticket Closed', meta: 'Open' }
  const reference = ticket && <Reference {...props} />
  const query = queryString.stringify({
    amount: ticket.amount / 100,
    date: depositDate,
  })

  return (
    <Ticket header={header} reference={reference}>
      <form>
        <h4 className={styles.section}>No further actions required.</h4>
        <h3 id="test-hook-funds-added" className={styles.sectionSmall}>
          Funds have been added to {account_number}.
        </h3>
        <div className="section">
          <Link className={styles.buttonLink} to={`/accounts/${account_number}`}>
            View Account
          </Link>
          <Link className={styles.buttonLink} to={`/accounts/${account_number}/trustee-receipt?${query}`}>
            View Trustee Receipt
          </Link>
          <Link className={styles.buttonLink} to={`/accounts/${account_number}/deposit`}>
            Add More Funds
          </Link>
        </div>
        {<DepositVerificationRef {...props} />}
      </form>
    </Ticket>
  )
}
