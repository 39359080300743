import React from 'react'
import FeeScheduleSection from './FeeScheduleSection'
import DepositDetailsSection from './DepositDetailsSection'

const DepositReviewRef = (props) => {
  const { amount, balance, customFee, feeRate, feeSchedule, enrollmentFee, isRefund, isTransfer, depositDate, depositType, imageKey } = props
  const hasEnrollmentFee = enrollmentFee > 0
  const fee = Math.round(((hasEnrollmentFee ? amount - enrollmentFee : amount) * feeRate) / 100)

  const hasAmount = amount > 0

  return (
    <>
      <DepositDetailsSection
        amount={amount}
        feeRate={feeRate}
        depositDate={depositDate}
        depositType={depositType}
        hasEnrollmentFee={hasEnrollmentFee}
        fee={fee}
        enrollmentFee={enrollmentFee}
        isRefund={isRefund}
        isTransfer={isTransfer}
        hasAmount={hasAmount}
        imageKey={imageKey}
      />
      {!isRefund && !isTransfer && (
        <FeeScheduleSection balance={balance} customFee={customFee} feeSchedule={feeSchedule} feeRate={feeRate} enrollmentFee={enrollmentFee} fee={fee} amount={amount} />
      )}
    </>
  )
}

export default DepositReviewRef
