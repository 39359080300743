// @flow
import React from 'react'
import type { Field } from 'lib/types'
import FieldWrapper from './FieldWrapper'
import renderCurrencyInput from './renderCurrencyInput'

const renderCurrencyInputField = (field: Field) => {
  const {
    autoFocus,
    button,
    children,
    label,
    meta,
    meta: { asyncValidating },
    placeholder,
    required,
  } = field

  const props = {
    asyncValidating,
    autoFocus,
    button,
    innerButton: children,
    label: label || placeholder,
    meta,
    name: field.input.name,
    required,
  }

  return <FieldWrapper {...props}>{renderCurrencyInput(field)}</FieldWrapper>
}

export default renderCurrencyInputField
