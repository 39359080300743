import React from 'react'
import styles from './styles'
import formatters from 'lib/formatters'

const FeeScheduleSection = ({ balance, customFee, feeSchedule, enrollmentFee, fee, feeRate, amount }) => {
  const hasEnrollmentFee = enrollmentFee > 0
  const formattedFeeSchedule = formatters.mapFeeSchedule(feeSchedule)

  return (
    <div className={styles.cardMarginBot}>
      <h5 className={styles.sectionTitle}>Fee Schedule</h5>
      <div className={styles.sectionNoMargin}>
        <div className={`${styles.depositCols} ${styles.feeSchedule}`}>
          {customFee ? (
            <>
              <p className={styles.depositLabel}>{customFee.name}</p>
              <p className={styles.depositValue}>{customFee.description}</p>
            </>
          ) : (
            <>
              <p className={styles.depositLabel}>{feeSchedule}</p>
              {formattedFeeSchedule.map((fee, index) => (
                <p key={index} className={styles.depositValue}>
                  <span className={styles.rate}>{fee.rate} </span>
                  <span className={styles.descriptionFull}>{fee.description}</span>
                </p>
              ))}
            </>
          )}
        </div>
        <div className={`${styles.alignRight} ${styles.depositCols} ${styles.feeScheduleRight}`}>
          <div>
            <p className={styles.depositLabel}>Total Balance on Account</p>
            <p className={styles.depositValue}>
              <span className={styles.ticketCreation}>
                At time of ticket creation <br />
              </span>
              {formatters.money(balance || 0)}
            </p>
          </div>
          <div>
            <p className={styles.depositLabel}>Schedule Fee Rate</p>
            <p className={styles.depositValue}>{(feeRate * 100).toFixed(2)}%</p>
          </div>
          <div>
            <p className={styles.depositLabel}>Schedule Admin Fee</p>
            <p className={styles.depositValue}>${(((amount - enrollmentFee) * feeRate) / 100.0).toFixed(2)}</p>
          </div>
          {hasEnrollmentFee && (
            <div>
              <p className={styles.depositLabel}>Enrollment Fee</p>
              <p className={styles.depositValue}>{formatters.money(enrollmentFee)}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeeScheduleSection
