// @flow
import React from 'react'
import moment from 'moment'
import { DOB_FORMAT, EVENT_TIMESTAMP_FORMAT, FILE_TIMESTAMP_FORMAT } from 'lib/constants'
import { capitalize } from 'lodash'

const MAX_CONVERSION_DATE = moment('2021-12-31')

const address = (val: Object) => {
  if (!val) return null
  let { street_1, street1, street_2, street2, city, state, zip } = val
  if (!street_1 && !street1) return null
  let street = street_1 || street1
  if (street_2 || street2) {
    street += `, ${street_2 || street2}`
  }
  city = city ? city + ',' : null
  return (
    <div>
      <div>{street}</div>
      <div>
        {city} {state} {zip}
      </div>
    </div>
  )
}

const age = (val: mixed) => {
  if (!val) return null
  if (typeof val === 'string') {
    return moment().diff(moment(val, DOB_FORMAT), 'years')
  }

  return moment().diff(val, 'years')
}

const apiErrors = (errors: Array<Object>) =>
  errors?.reduce((acc, error) => {
    const { location: key, locationType, message } = error

    if (locationType === 'field') {
      Array.isArray(acc[key]) ? acc[key].push(message) : (acc[key] = message)
    } else {
      acc[key] = apiErrors(error.errors)
    }
    return acc
  }, {})

const date = (val: string) => {
  if (!val) return null
  const day = moment(val)
  return day.format(DOB_FORMAT)
}

const dateTimeDiff = (iso8601datetime: string, measurement: string) => {
  const dateTimeInUtc = moment.utc(iso8601datetime)

  return moment().diff(dateTimeInUtc, measurement)
}

const ectoDate = (val: Object) => {
  if (!val) return null
  const date = {
    ...val,
    month: val.month - 1,
  }
  return moment.utc(date).local()
}

const eventTimestamp = (val: Object) => {
  if (!val) return null
  return moment.utc(val).local().format(EVENT_TIMESTAMP_FORMAT)
}

const fileTimestamp = (val: Object) => {
  if (!val) return null
  return moment.utc(val).local().format(FILE_TIMESTAMP_FORMAT)
}

const grantorOrFundedBy = (val: Object) => {
  let label = 'Grantor'

  if (val) {
    if (val == 'gsnt_new') {
      label = 'Funded By'
    } else if (val.journal_name && val.journal_name == 'gsnt_new') {
      label = 'Funded By'
    } else if (val.account_number && val.account_number.startsWith('J')) {
      label = 'Funded By'
    }
  }

  return label
}

const integer = (val: number) => {
  const num = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  return <span>{num}</span>
}

const lastUpdate = (iso8601datetime: string) => {
  return moment.utc(iso8601datetime).fromNow()
}

const inputMoney = (val, precision = 2) => {
  return (Number.isInteger(val) ? val / 100 : val * 1).toFixed(precision)
}

const money = (val: number, precision: number = 2) => {
  const num = inputMoney(val, precision)
  return `$${moneyCommas(num)}`
}

const moneyCommas = (val: string) => {
  return val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

const moneyField = (val: number) => {
  if (!val) return null
  return (val / 100).toFixed(2)
}

const naiveDateTime = (val: string) => {
  if (!val) return null
  return moment.utc(val)
}

const writtenDate = (val: string) => {
  if (!val) return null
  return moment(val).format('MMMM Do, YYYY')
}

const percentage = (val: number) => {
  if (val > 1) return null
  return `${(val * 100).toFixed(2)}%`
}

const phone = (val: string) => {
  if (!val) return null
  const matches = val.match(/\d/g)
  if (!matches) return null
  return matches.join('').replace(/(\d{3})(\d{3})(\d{4})/, '($1)\u00A0$2\u2011$3')
}

const phoneNumbers = (numbers: Object) => {
  let { cell_phone, day_phone, evening_phone, fax } = numbers
  return (
    <section className="rowGroup">
      <div className="rgRow">
        <span className="rgLabel">Cell:</span>
        <span className="rgValue">{phone(cell_phone)}</span>
      </div>
      <div className="rgRow">
        <span className="rgLabel">Day:</span>
        <span className="rgValue">{phone(day_phone)}</span>
      </div>
      <div className="rgRow">
        <span className="rgLabel">Evening:</span>
        <span className="rgValue">{phone(evening_phone)}</span>
      </div>
      <div className="rgRow">
        <span className="rgLabel">Fax:</span>
        <span className="rgValue">{phone(fax)}</span>
      </div>
    </section>
  )
}

const ein = (val: string) => {
  if (!val) return null
  const matches = val.match(/\d/g)
  if (!matches) return null
  return matches.join('').replace(/(\d{2})(\d{7})/, '$1\u2011$2')
}

const ssn = (val: string) => {
  if (!val) return null
  const matches = val.match(/\d/g)
  if (!matches) return null
  return matches.join('').replace(/(\d{3})(\d{2})(\d{4})/, '$1\u2011$2\u2011$3')
}

const timestamp = (iso8601datetime: string) => {
  return moment.utc(iso8601datetime).local().format(EVENT_TIMESTAMP_FORMAT)
}

const toSnakeCase = (str) => {
  return str
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1]
    })
    .toLowerCase()
}

const toTitleCase = (str) => {
  return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

const transactionDescription = (description: string, date) => {
  const stdDate = moment(date)
  if (description.length > 0) {
    return description
  } else if (MAX_CONVERSION_DATE.isBefore(stdDate)) {
    return '(description missing)'
  } else {
    return 'Conversion Balance'
  }
}

const legibleDepositType = (type) => {
  switch (type) {
    case 'direct_deposit':
      return 'Direct Deposit'
    case 'wire':
      return 'Wire'
    case 'check':
      return 'Check'
    case 'cashiers_check':
      return "Cashier's Ck"
  }
}

const legibleNotificationTag = (tag) => {
  let legible = tag
  switch (tag) {
    case 'general':
      legible = ''
      break
  }
  return legible
}

const legibleNotificationType = (type) => {
  let legible = type
  if (type == 'gpt_account_creation') {
    legible = 'Account Creation'
  }
  return legible
}

const legibleAccountStatus = (status) => {
  switch (status) {
    case 'approved':
      return 'Active'
    default:
      return capitalize(status)
  }
}

const legiblePotentialClientStatus = (status) => {
  switch (status) {
    case 'awaiting_staff_review_1':
      return 'New Case Entry'
    case 'awaiting_staff_review_2':
      return 'Second Staff Review'
    case 'awaiting_attorney_review':
    case 'awaiting_attorney_review_1': // Kept for backwards compatibility
    case 'awaiting_attorney_review_2': // Kept for backwards compatibility
      return 'Attorney Approval'
    case 'approved':
      return 'Approved'
    case 'created':
      return 'Created'
    default:
      return status
  }
}

const legibleSubaccountType = (type) => {
  let legible = type

  switch (type) {
    case 'individual_investment':
      return 'Individual Investment'
    case 'asset':
      return 'Asset'
    case 'msa':
      return 'MSA'
    default:
      return legible
  }
}

const legibleStation = (string) => {
  let legible = toTitleCase(string.replace(/_/g, ' '))
  switch (legible) {
    case 'Co Trustee Approval':
      legible = 'Co-Trustee Approval'
      break
  }
  return legible
}

const legibleTrack = (string) => {
  let legible = toTitleCase(string.replace(/_/g, ' '))
  switch (legible) {
    case 'Gpt Account Creation':
      legible = 'Account Creation'
      break
  }
  return legible
}

const legibleWarningBase = (type) => {
  switch (type) {
    case 'early_date':
      return 'the deposit date is more than 15 days ago'
    case 'duplicate_amount_and_day':
      return 'a previous deposit for this account was made ' + 'on the same day with the same amount'
    case 'duplicate_check':
      return 'this account has a previous deposit with the same check number'
    case 'duplicate_check_and_amount':
      return 'this account has a previous deposit with ' + 'the same check number and amount'
  }
}

const legibleWarning = (type) => {
  const base = legibleWarningBase(type)
  return base.charAt(0).toUpperCase() + base.slice(1)
}

const legibleWarningAcknowledgement = (type) => {
  const base = legibleWarningBase(type)
  return 'I acknowledge that ' + base
}

const legiblePaymentType = (type) => {
  switch (type) {
    case 'ach':
      return 'ACH'
    case 'cashier_check':
      return 'Cashiers'
    case 'check_external':
      return 'Check'
    case 'check_in_house':
      return 'PIH'
    case 'gt_credit_card':
      return 'GT Credit Card'
    case 'p_card':
      return 'P-Card'
    case 'pay_by_phone':
      return 'Phone'
    case 'pay_online':
      return 'Online'
    case 'wire':
      return 'Wire'
    default:
      return type
  }
}

const truncatedString = (string, max) => {
  let truncated = ''
  if (string) {
    truncated = string.length > max ? string.substring(0, max - 3) + '...' : string
  }
  return truncated
}

const unsnake = (string) => {
  if (!string) return null
  const spaced_out = string.replaceAll('_', ' ')
  return spaced_out.charAt(0).toUpperCase() + spaced_out.slice(1)
}

const usernameFromEmail = (email) => {
  if (email && email.includes('@')) {
    return capitalize(email.split('@')[0])
  } else {
    return ''
  }
}

const currency = (amount) => {
  if (!amount) return amount
  const numericValue = parseFloat(amount.replace(/[^\d.]/g, ''))
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(parseFloat(numericValue))
}

function getWeekday(date) {
  const today = moment().startOf('day')
  const inputDate = moment(date).startOf('day')

  if (inputDate.isSame(today, 'day')) {
    return 'Today'
  } else if (inputDate.isSame(today.clone().add(1, 'day'), 'day')) {
    return 'Tomorrow'
  } else if (inputDate.isSame(today.clone().subtract(1, 'day'), 'day')) {
    return 'Yesterday'
  } else {
    return inputDate.format('dddd')
  }
}

function getDateOn(months, direction, day = null) {
  const currentDate = new Date()
  if (day != null) {
    currentDate.setDate(day)
  }
  const newDate = new Date(currentDate)

  if (direction === 'back') {
    newDate.setMonth(newDate.getMonth() - months)
  } else if (direction === 'forth') {
    newDate.setMonth(newDate.getMonth() + months)
  } else {
    throw new Error("Invalid direction. Use 'back' or 'forth'.")
  }

  return newDate.toISOString().split('T')[0]
}

function mapFeeSchedule(trustType) {
  const schedules = {
    D4C: [
      { rate: '3.00%', description: 'Less than $250,000' },
      { rate: '2.25%', description: '$250,000 - $500,000' },
      { rate: '1.50%', description: '$500,001 +' },
    ],
    D4A: [
      { rate: '4.00%', description: 'Less than $100,000' },
      { rate: '3.00%', description: '$100,000 - $250,000' },
      { rate: '2.50%', description: '$250,000 - $500,000' },
      { rate: '1.50%', description: '$500,000 - $1,000,000' },
      { rate: '1.00%', description: '$1,000,000 - $2,000,000' },
      { rate: '0.80%', description: '$2,000,000 - $3,000,000' },
      { rate: '0.60%', description: '$3,000,000 - $4,000,000' },
      { rate: '0.50%', description: '$4,000,000 +' },
    ],
    NSNT: [
      { rate: '3.00%', description: 'Less than $100,000' },
      { rate: '2.50%', description: '$100,000 - $250,000' },
      { rate: '2.00%', description: '$250,000 - $500,000' },
      { rate: '1.50%', description: '$500,000 - $1,000,000' },
      { rate: '1.00%', description: '$1,000,000 - $2,000,000' },
      { rate: '0.80%', description: '$2,000,000 - $3,000,000' },
      { rate: '0.60%', description: '$3,000,000 +' },
    ],
    GSNT: [
      { rate: '2.50%', description: 'Less than $250,000' },
      { rate: '2.00%', description: '$250,000 - $500,000' },
      { rate: '1.50%', description: '$500,000 - $1,000,000' },
      { rate: '1.00%', description: '$1,000,000 - $2,000,000' },
      { rate: '0.80%', description: '$2,000,000 - $3,000,000' },
      { rate: '0.60%', description: '$3,000,000 +' },
    ],
    PROGRESSIVE_D4C: [
      { rate: '3.00%', description: 'Less than $250,000', formula: 'balance * 0.03' },
      { rate: '2.25%', description: '$250,000 - $500,000', formula: '(250000 * 0.03) + ((balance - 250000) * 0.0225)' },
      { rate: '1.50%', description: '$500,001 +', formula: '(250000 * 0.03) + (250000 * 0.0225) + ((balance - 500000) * 0.015)' },
    ],
    PROGRESSIVE_D4A: [
      { rate: '4.00%', description: 'Less than $100,000', formula: 'balance * 0.04' },
      { rate: '3.00%', description: '$100,000 - $250,000', formula: '(100000 * 0.04) + ((balance - 100000) * 0.03)' },
      { rate: '2.50%', description: '$250,000 - $500,000', formula: '(100000 * 0.04) + (150000 * 0.03) + ((balance - 250000) * 0.025)' },
      {
        rate: '1.50%',
        description: '$500,000 - $1,000,000',
        formula: '(100000 * 0.04) + (150000 * 0.03) + (250000 * 0.025) + ((balance - 500000) * 0.015)',
      },
      {
        rate: '1.00%',
        description: '$1,000,000 - $2,000,000',
        formula: '(100000 * 0.04) + (150000 * 0.03) + (250000 * 0.025) + (500000 * 0.015) + ((balance - 1000000) * 0.01)',
      },
      {
        rate: '0.80%',
        description: '$2,000,000 - $3,000,000',
        formula: '(100000 * 0.04) + (150000 * 0.03) + (250000 * 0.025) + (500000 * 0.015) + (1000000 * 0.01) + ((balance - 2000000) * 0.008)',
      },
      {
        rate: '0.60%',
        description: '$3,000,000 - $4,000,000',
        formula:
          '(100000 * 0.04) + (150000 * 0.03) + (250000 * 0.025) + (500000 * 0.015) + (1000000 * 0.01) + (1000000 * 0.008) + ((balance - 3000000) * 0.006)',
      },
      {
        rate: '0.50%',
        description: '$4,000,000 +',
        formula:
          '(100000 * 0.04) + (150000 * 0.03) + (250000 * 0.025) + (500000 * 0.015) + (1000000 * 0.01) + (1000000 * 0.008) + (1000000 * 0.006) + ((balance - 4000000) * 0.005)',
      },
    ],
    PROGRESSIVE_NSNT: [
      { rate: '3.00%', description: 'Less than $100,000', formula: 'balance * 0.03' },
      { rate: '2.50%', description: '$100,000 - $250,000', formula: '(100000 * 0.03) + ((balance - 100000) * 0.025)' },
      { rate: '2.00%', description: '$250,000 - $500,000', formula: '(100000 * 0.03) + (150000 * 0.025) + ((balance - 250000) * 0.02)' },
      {
        rate: '1.50%',
        description: '$500,000 - $1,000,000',
        formula: '(100000 * 0.03) + (150000 * 0.025) + (250000 * 0.02) + ((balance - 500000) * 0.015)',
      },
      {
        rate: '1.00%',
        description: '$1,000,000 - $2,000,000',
        formula: '(100000 * 0.03) + (150000 * 0.025) + (250000 * 0.02) + (500000 * 0.015) + ((balance - 1000000) * 0.01)',
      },
      {
        rate: '0.80%',
        description: '$2,000,000 - $3,000,000',
        formula: '(100000 * 0.03) + (150000 * 0.025) + (250000 * 0.02) + (500000 * 0.015) + (1000000 * 0.01) + ((balance - 2000000) * 0.008)',
      },
      {
        rate: '0.60%',
        description: '$3,000,000 +',
        formula:
          '(100000 * 0.03) + (150000 * 0.025) + (250000 * 0.02) + (500000 * 0.015) + (1000000 * 0.01) + (1000000 * 0.008) + ((balance - 3000000) * 0.006)',
      },
    ],
    PROGRESSIVE_GSNT: [
      { rate: '2.50%', description: 'Less than $250,000', formula: 'balance * 0.025' },
      { rate: '2.00%', description: '$250,000 - $500,000', formula: '(250000 * 0.025) + ((balance - 250000) * 0.02)' },
      { rate: '1.50%', description: '$500,000 - $1,000,000', formula: '(250000 * 0.025) + (250000 * 0.02) + ((balance - 500000) * 0.015)' },
      {
        rate: '1.00%',
        description: '$1,000,000 - $2,000,000',
        formula: '(250000 * 0.025) + (250000 * 0.02) + (500000 * 0.015) + ((balance - 1000000) * 0.01)',
      },
      {
        rate: '0.80%',
        description: '$2,000,000 - $3,000,000',
        formula: '(250000 * 0.025) + (250000 * 0.02) + (500000 * 0.015) + (1000000 * 0.01) + ((balance - 2000000) * 0.008)',
      },
      {
        rate: '0.60%',
        description: '$3,000,000 +',
        formula: '(250000 * 0.025) + (250000 * 0.02) + (500000 * 0.015) + (1000000 * 0.01) + (1000000 * 0.008) + ((balance - 3000000) * 0.006)',
      },
    ],
  }

  return schedules[trustType] ?? []
}

export default {
  getWeekday,
  currency,
  address,
  age,
  apiErrors,
  date,
  dateTimeDiff,
  ectoDate,
  ein,
  eventTimestamp,
  fileTimestamp,
  grantorOrFundedBy,
  inputMoney,
  integer,
  lastUpdate,
  legibleAccountStatus,
  legibleDepositType,
  legibleNotificationTag,
  legibleNotificationType,
  legiblePaymentType,
  legiblePotentialClientStatus,
  legibleSubaccountType,
  legibleStation,
  legibleTrack,
  legibleWarning,
  legibleWarningAcknowledgement,
  money,
  moneyCommas,
  moneyField,
  naiveDateTime,
  percentage,
  phone,
  phoneNumbers,
  ssn,
  timestamp,
  toSnakeCase,
  toTitleCase,
  transactionDescription,
  truncatedString,
  unsnake,
  usernameFromEmail,
  writtenDate,
  getDateOn,
  mapFeeSchedule,
}
