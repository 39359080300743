import React from 'react'
import IconBox from './IconBox'
/* eslint-disable max-len */

export function IconSearch() {
  return (
    <IconBox>
      <svg
        className="feather feather-search"
        fill="none"
        height="20"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11" cy="11" r="8" />
        <line x1="21" x2="16.65" y1="21" y2="16.65" />
      </svg>
    </IconBox>
  )
}
