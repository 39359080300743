// @flow
import React from 'react'
import { connect } from 'react-redux'
import cleaners from 'lib/field/cleaners'
import formatters from 'lib/formatters'
import moment from 'moment'
import { httpGet, httpPost } from 'lib/http'
import { Template } from './Template'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  location: Object,
}

class Deposit extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      approvedEarlyDate: false,
      errors: {},
      isDepositDateEarly: false,
      isSubmitting: false,
      referenceData: null,
      values: {
        amount: '',
        deposit_date: '',
        deposit_type: 'check',
        is_refund: false,
        is_transfer: false,
      },
    }
  }

  componentDidMount() {
    this.fetchReferenceData()
  }

  canSubmit = () => {
    const { approvedEarlyDate, isDepositDateEarly, isSubmitting, referenceData } = this.state

    const submittingOK = !isSubmitting
    const referenceOK = referenceData && !['pending', 'closed'].includes(referenceData.status)
    const dateOK = !isDepositDateEarly || approvedEarlyDate
    const amountOK = this.state.values.amount > 0

    return submittingOK && referenceOK && dateOK && amountOK
  }

  checkDate = (date) => {
    const earlyDate = moment().add(-15, 'd')
    const momentSelected = moment(date)

    if (momentSelected.isBefore(earlyDate)) {
      this.setState({ isDepositDateEarly: true })
    } else {
      this.setState({ isDepositDateEarly: false })
    }
  }

  fetchReferenceData = () => {
    const { number } = this.props.location.params

    httpGet(`/admin/accounts/${number}/deposits/new`).then((data) => {
      const referenceData = {
        number,
        beneficiary: { name: data.beneficiary_name },
        status: data.account_status,
      }
      this.setState({ referenceData })
    })
  }

  handleBlur = ({ target }) => {
    if (target.name === 'amount') {
      const { values } = this.state
      const newValues = {
        ...values,
        amount: formatters.inputMoney(values.amount),
      }
      this.setState({ values: newValues })
    }
  }

  handleChange = ({ target }) => {
    const errors = { ...this.state.errors, [target.name]: null }
    let values = { ...this.state.values, [target.name]: target.value }

    if (['is_refund', 'is_transfer'].includes(target.name)) {
      const value = typeof target.value === 'string' ? target.value === 'true' : target.value
      values = {
        ...this.state.values,
        [target.name]: value,
      }
    }
    const approvals = ['approvedEarlyDate']

    if (approvals.includes(target.name)) {
      this.setState({ [target.name]: target.checked })
    }

    if (target.name == 'deposit_date') {
      this.checkDate(target.value)
    }

    // handle errors
    if (target.name === 'amount') {
      const amount = cleaners.money(target.value)
      if (amount <= 0) {
        errors.amount = 'Deposit amount must be greater than $0.'
      }
    }

    if (target.name === 'verification_image') {
      errors.check = target.value == null ? 'Deposit image must be uploaded.' : null
    }

    this.setState({ errors, values })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({ isSubmitting: true }, this.postDeposit)
  }

  parseJSONError = (acc, e) => {
    switch (e.locationType) {
      case 'field':
        acc[e.location] = e.message
        return acc
      case 'fieldGroup':
        acc[e.location] = e.errors.reduce(this.parseJSONError, {})
        return acc
    }
  }

  validateErrors = (errors) => {
    let errorCount = 0
    let newErrors = {}

    if (errors?.verification_image == null) {
      newErrors.check = 'Deposit image must be uploaded.'
      errorCount += 1
    }
    if (errors?.deposit_date == null) {
      newErrors.deposit_date = 'Please select a deposit date.'
      errorCount += 1
    }

    if (errorCount > 0) {
      this.setState({ errors: newErrors, isSubmitting: false })
    }

    return errorCount > 0
  }

  postDeposit = () => {
    const { values } = this.state
    const params = {
      ...values,
      amount: cleaners.money(values.amount),
      deposit_date: this.scrub(values.deposit_date),
    }

    if (this.validateErrors(params)) {
      return
    }

    httpPost(`/admin/accounts/${this.props.location.params.number}/deposits`, params)
      .then((data) => this.props.location.navigate(`/tickets/${data.id}`))
      .catch(({ errors }) => {
        if (errors === undefined) {
          this.setState({ errors: 'unexpected' })
        } else {
          this.setState({ errors: errors.reduce(this.parseJSONError, {}) })
        }
      })
      .then(() => this.setState({ isSubmitting: false }))
  }

  scrub = (value) => {
    return value === '' ? null : value
  }

  shouldCheckFieldsRender = () => {
    return ['check', 'cashiers_check'].includes(this.state.values.deposit_type)
  }

  render() {
    return (
      <Template
        approvedEarlyDate={this.state.approvedEarlyDate}
        canSubmit={this.canSubmit()}
        errors={this.state.errors}
        isDepositDateEarly={this.state.isDepositDateEarly}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        referenceData={this.state.referenceData}
        values={this.state.values}
      />
    )
  }
}

export default withRouter(connect()(Deposit))
