// @flow
import React from 'react'
import type { Field } from 'lib/types'
import CurrencyFormatInput from '../CurrencyFormatInput'
import styles from './styles'

const renderCurrencyInput = (field: Field) => {
  const { autoFocus, input, placeholder, disabled, flattenValue } = field
  const inputProps = {
    autoFocus,
    name: input.name,
    placeholder,
    disabled,
  }

  return (
    <div className={`${styles.inputContainer} ${styles.mono}`}>
      <CurrencyFormatInput {...input} {...inputProps} className={styles.input} flattenValue={flattenValue} onBlur={undefined} />
    </div>
  )
}

export default renderCurrencyInput
