// @flow
import React from 'react'
import { S3FileInput } from 'components/S3FileInput'
import styles from './styles'
import SectionHeader from 'components/SectionHeader'
import DateInput from 'components/DateInput'
import CurrencyFormatInput from 'components/CurrencyFormatInput'

type Props = {
  approvedEarlyDate: boolean,
  errors: Object,
  isDepositDateEarly: boolean,
  onBlur: Function,
  onChange: Function,
  values: Object,
}

const testHookClass = 'test-hook-row-error'

const renderLabelError = (error) => <strong>&ensp;&middot;&ensp;{error}</strong>

const renderCheckFields = ({ errors, onChange, values }: Props) => (
  <div>
    <div className={styles.formItem}>
      <div className={errors.check && styles.errorWrapper}>
        <label className={`${styles.label} ${testHookClass}`} htmlFor="verification_image">
          Deposit Image
          {errors.check && renderLabelError(errors.check)}
        </label>
        <S3FileInput
          name="verification_image"
          onChange={onChange}
          value={values.verification_image}
          existing={{ key: values.verification_image_key }}
        />
      </div>
    </div>
  </div>
)
export const PolicyInformation = () => {
  return (
    <section className={styles.cardMarginBot}>
      <SectionHeader title="Important Policy Information" />

      <section className={styles.sectionNoMargin}>
        <p className={styles.policyInfo}>
          Guardian Trust Lorem ipsum dolor sit amet, homero convenire constituto an nec, posse partem equidem te his, an mea inani honestatis. Porro
          elitr indoctum sea at, ceteros docendi corpora pri ex. Ne nec repudiare cotidieque, ei est praesent definiebas definitiones. Et mei modus
          interpretaris. Congue admodum has ne, vim quas vocibus rationibus et. Sit an eloquentiam voluptatibus, eum ludus prompta facilis et, quo eu
          elitr theophrastus.
        </p>
      </section>
    </section>
  )
}

export const DepositFunds = (props: Props) => {
  const { approvedEarlyDate, errors, isDepositDateEarly, onChange, values } = props
  const shouldCheckFieldsRender = ['check', 'cashiers_check'].includes(values.deposit_type)
  const requiresDepositVerification = ['wire', 'direct_deposit'].includes(values.deposit_type)

  return (
    <section className={styles.depositsContainer}>
      <section className={styles.cardMarginBot}>
        <SectionHeader title="Initial Deposit Information" />
        <section className={styles.sectionNoMargin}>
          <div className={styles.formItemHalf}>
            <label className={styles.label} htmlFor="deposit_type">
              Deposit Type
            </label>
            <div className={styles.selectWrapper}>
              <select className={styles.select} name="deposit_type" onChange={onChange} value={values.deposit_type}>
                <option value="check">Check</option>
                <option value="cashiers_check">Cashier&rsquo;s Check</option>
                <option value="direct_deposit">Direct Deposit</option>
                <option value="wire">Wire</option>
              </select>
            </div>
          </div>
          <div className={styles.formItemMono}>
            <div className={errors.deposit_date && styles.errorWrapper}>
              <label className={`${styles.label} ${testHookClass}`} htmlFor="deposit_date">
                Deposit Date
                {errors.deposit_date && renderLabelError(errors.deposit_date)}
              </label>
              <DateInput name="deposit_date" value={values.deposit_date} onChange={onChange} />
            </div>
          </div>
          <div className={styles.formItemMono}>
            <div className={errors.amount && styles.errorWrapper}>
              <label className={`${styles.label} ${testHookClass}`} htmlFor="amount">
                Deposit Amount
                {errors.amount && renderLabelError(errors.amount)}
              </label>

              <CurrencyFormatInput className={styles.input} name="amount" setValue={onChange} placeholder="$0.00" value={values.amount} step={0.01} />
            </div>
          </div>
          <div className={styles.inputLabelGroup}>
            <div className={styles.radioButtonGroup}>
              <label className={styles.label} htmlFor="is_refund">
                Is this a refund?
              </label>
              <div className={styles.buttons}>
                <input
                  type="radio"
                  id="is_refund_yes"
                  name="is_refund"
                  value="true"
                  onChange={(e) => {
                    console.log('e', values.is_refund, e.target.value === 'true')
                    onChange({ target: { name: 'is_refund', value: e.target.value === 'true' } })
                  }}
                  checked={values.is_refund === true}
                />
                <label htmlFor="is_refund_yes">Yes</label>
                <input
                  type="radio"
                  id="is_refund_no"
                  name="is_refund"
                  value="false"
                  onChange={(e) => onChange({ target: { name: 'is_refund', value: e.target.value === 'true' } })}
                  checked={values.is_refund === false}
                />
                <label htmlFor="is_refund_no">No</label>
              </div>
            </div>
          </div>
          <div className={styles.inputLabelGroup}>
            <div className={styles.radioButtonGroup}>
              <label className={styles.label} htmlFor="is_transfer">
                Is this a transfer from investment?
              </label>
              <div className={styles.buttons}>
                <input
                  type="radio"
                  id="is_transfer_yes"
                  name="is_transfer"
                  value="true"
                  onChange={(e) => onChange({ target: { name: 'is_transfer', value: e.target.value === 'true' } })}
                  checked={values.is_transfer === true}
                />
                <label htmlFor="is_transfer_yes">Yes</label>
                <input
                  type="radio"
                  id="is_transfer_no"
                  name="is_transfer"
                  value="false"
                  onChange={(e) => onChange({ target: { name: 'is_transfer', value: e.target.value === 'true' } })}
                  checked={values.is_transfer === false}
                />
                <label htmlFor="is_transfer_no">No</label>
              </div>
            </div>
          </div>
          {requiresDepositVerification && (
            <div className={styles.formItem}>
              <div className={errors.verification_image && styles.errorWrapper}>
                <label className={styles.label} htmlFor="verification_image">
                  Deposit Verification
                  {errors.verification_image && renderLabelError(errors.verification_image)}
                </label>
                <S3FileInput
                  name="verification_image"
                  onChange={onChange}
                  value={values.verification_image}
                  existing={{ key: values.verification_image_key }}
                />
              </div>
            </div>
          )}
          {shouldCheckFieldsRender && renderCheckFields(props)}
          <div>
            {isDepositDateEarly && (
              <div className={styles.warningLabelGroup}>
                <input id="approvedEarlyDate" name="approvedEarlyDate" type="checkbox" checked={approvedEarlyDate} onChange={onChange} />
                <label className={styles.warningLabel} htmlFor="approvedEarlyDate">
                  I acknowledge that the deposit date is more than 15 days ago
                </label>
              </div>
            )}
          </div>
        </section>
      </section>
      {/* <PolicyInformation /> */}
    </section>
  )
}
