import React, { useState } from 'react'
import SectionHeader from '../Header/SectionHeader'
import styles from '../Main/styles.css'
import CheckboxSelect from '../../../../../components/CheckBoxSelect'

const ActionsNeeded = ({ requiredActions, completedActions, setCompletedActions, checkColor, sectionTitle }) => {
  const updateData = (data) => data?.map((i) => ({ label: i, id: i })) ?? []

  return (
    <section className={styles.cardMarginBot}>
      <SectionHeader title={sectionTitle ?? 'Processing Actions'} />

      <div className={styles.actionsNeededContainer}>
        <CheckboxSelect
          options={updateData(requiredActions)}
          selectedOptions={completedActions}
          onChange={setCompletedActions}
          checkColor={checkColor}
        />
      </div>
    </section>
  )
}

export default ActionsNeeded
