// @flow
import { Dialog } from 'components/Dialog'
import FormButtons from 'components/FormButtons'
import Ticket from 'components/Ticket'
import React from 'react'
import DepositReviewRef from './DepositReviewRef'
import { Reference } from './ReferenceRef'
import { WarningsAcknowledgementRef } from './WarningsAcknowledgementRef'
import styles from './styles'
import RejectionNotesRef from './RejectionNotesRef'

type Props = {
  canSubmit: Function,
  dispatch: Function,
  isDeleting: boolean,
  isSubmitting: boolean,
  onBeginDelete: boolean,
  onCancelDelete: boolean,
  onChange: Function,
  onSubmit: Function,
  onDelete: Function,
  ticket: Object,
  values: Object,
}

const FeeStation = (props: Props) => {
  const { canSubmit, isDeleting, isSubmitting, onBeginDelete, onCancelDelete, onDelete, onChange, onSubmit, ticket, values } = props
  const { fee_schedule } = ticket
  const { acknowledgeDuplicateCheck, acknowledgeEarlyDate } = values
  const header = { subheader: 'Deposit', title: 'Fee Calculation' }
  const reference = ticket && <Reference {...props} />

  return (
    <Ticket header={header} reference={reference}>
      {ticket.rejection_notes && <RejectionNotesRef notes={ticket.rejection_notes} />}
      <form onSubmit={onSubmit}>
        <p className={styles.infoText}>Confirm fee calculations.</p>
        <DepositReviewRef
          amount={ticket.amount}
          balance={ticket.balance}
          customFee={ticket.custom_fee}
          feeRate={ticket.fee_rate}
          feeSchedule={fee_schedule}
          hasCustomFeeSchedule={ticket['custom_fee_schedule?']}
          enrollmentFee={ticket['enrollment_fee']}
          isRefund={ticket.is_refund}
          isTransfer={ticket.is_transfer}
          depositDate={ticket.deposit_date}
          depositType={ticket.deposit_type}
          imageKey={ticket.verification_image_key}
        />
        <WarningsAcknowledgementRef
          warnings={ticket.warnings}
          onChange={onChange}
          acknowledgeEarlyDate={acknowledgeEarlyDate}
          acknowledgeDuplicateCheck={acknowledgeDuplicateCheck}
        />
        <FormButtons disabled={!canSubmit} onDelete={onBeginDelete} submitLabel="Save and Continue" submitting={isSubmitting} />
      </form>
      {isDeleting && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="Yes, Delete"
          headerText="Are you sure you want to delete this deposit?"
          onCancel={onCancelDelete}
          onConfirm={onDelete}
        />
      )}
    </Ticket>
  )
}

export { FeeStation }
