// @flow
import { DepositFunds } from 'components/DepositFunds'
import { Dialog } from 'components/Dialog'
import { ApprovalRef } from 'components/References'
import Ticket from 'components/Ticket'
import React from 'react'
import { Reference } from './ReferenceRef'
import { WarningsAcknowledgementRef } from './WarningsAcknowledgementRef'
import styles from './styles'

type Props = {
  canSubmit: boolean,
  dispatch: Function,
  errors: Object,
  isSubmitting: boolean,
  onBlur: Function,
  onChange: Function,
  onSubmit: Function,
  onDelete?: Function,
  ticket: Object,
  values: Object,
}

export const ResolutionStation = (props: Props) => {
  const { isDeleting, canSubmit, isSubmitting, onBlur, onChange, onBeginDelete, onCancelDelete, onDelete, onSubmit, ticket, values } = props
  const { acknowledgeDuplicateCheck, acknowledgeEarlyDate } = values
  const header = { subheader: 'Deposit', title: 'Resolution' }
  const reference = ticket && <Reference {...props} />

  return (
    <Ticket header={header} reference={reference}>
      <ApprovalRef rejection_notes={ticket.rejection_notes} />
      <h4 className={styles.resolutionHeader}>Resolve deposit information.</h4>
      <form onSubmit={onSubmit}>
        <DepositFunds errors={props.errors} onBlur={onBlur} onChange={onChange} values={values} />
        <WarningsAcknowledgementRef
          warnings={ticket.warnings}
          onChange={props.onChange}
          acknowledgeEarlyDate={acknowledgeEarlyDate}
          acknowledgeDuplicateCheck={acknowledgeDuplicateCheck}
        />

        <section className={styles.buttons}>
          <div className={styles.buttonsLeft}>
            {onDelete && (
              <button className={styles.buttonDanger} disabled={isSubmitting} onClick={onBeginDelete}>
                Delete
              </button>
            )}
          </div>
          <div className={styles.buttonsRight}>
            <button className={styles.button} disabled={!canSubmit}>
              Save and Continue
            </button>
          </div>
        </section>
      </form>

      {isDeleting && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="Yes, Delete"
          headerText="Are you sure you want to delete this deposit?"
          onCancel={onCancelDelete}
          onConfirm={onDelete}
        />
      )}
    </Ticket>
  )
}
