// @flow
import { DepositFunds } from 'components/DepositFunds'
import FormButtons from 'components/FormButtons'
import Ticket from 'components/Ticket'
import React from 'react'
import GPTAccountRefV2 from '../../../../../../components/References/GPTAccountRef.v2'
import styles from './styles'

type Props = {
  approvedEarlyDate: boolean,
  canSubmit: boolean,
  errors: Object,
  isDepositDateEarly: boolean,
  onBlur: Function,
  onChange: Function,
  onSubmit: Function,
  referenceData: Object,
  values: Object,
}

const renderReference = (data) => <GPTAccountRefV2 {...data} />

export const Template = (props: Props) => {
  const { errors, onBlur, onChange, onSubmit, referenceData, values } = props
  const header = { title: 'Deposit', subheader: 'Deposit Data' }

  return (
    <Ticket header={header}>
      <form onSubmit={onSubmit} className={styles.form}>
        <DepositFunds
          approvedEarlyDate={props.approvedEarlyDate}
          errors={props.errors}
          isDepositDateEarly={props.isDepositDateEarly}
          onBlur={onBlur}
          onChange={onChange}
          values={values}
        />
        <FormButtons disabled={!props.canSubmit} errors={errors} submitLabel="Save and Continue" />
      </form>

      {referenceData && renderReference(referenceData)}
    </Ticket>
  )
}
