import GPTAccountRefV2 from 'components/References/GPTAccountRef.v2'
import { renderHistory } from 'components/Ticket/component'
import { Warnings } from 'components/Warnings'
import { Notes } from 'components/Notes'

export const Reference = ({ dispatch, ticket }) => (
  <section>
    <GPTAccountRefV2 beneficiary={{ name: ticket.beneficiary_name }} number={ticket.account_number} status={ticket.account_status}>
      <Warnings warnings={ticket.warnings} />
      <Notes accountNumber={ticket.account_number} dispatch={dispatch} notes={ticket.notes} />
      {renderHistory(ticket.history)}
    </GPTAccountRefV2>
  </section>
)
