import { Notes } from 'components/Notes'
import { ContactRef } from 'components/References'
import GPTAccountRefV2 from 'components/References/GPTAccountRef.v2'
import { renderHistory } from 'components/Ticket/component'
import React from 'react'

type Props = {
  hideContactRef: boolean,
  dispatch: Function,
  ticket: Object,
}

const contactRef = (hide, ticket) => {
  if (hide) {
    return null
  }

  return <ContactRef collapse={true} contact={ticket.beneficiary} edit={true} title="Beneficiary" />
}

const Reference = ({ hideContactRef, dispatch, ticket }: Props) => (
  <section>
    <GPTAccountRefV2 beneficiary={{ name: ticket.beneficiary_name }} number={ticket.account_number} status={ticket.account_status}>
      {contactRef(hideContactRef, ticket)}
      <Notes accountNumber={ticket.account_number} dispatch={dispatch} notes={ticket.notes} />
      {renderHistory(ticket.history)}
    </GPTAccountRefV2>
  </section>
)

export default Reference
